const query = `
query view($hash: String!, $data: String!) {
	status: signingContracts(hash: $hash, data: $data) {
		contract { status }
		master { status }
		coda { status }
	}
}`.trim();

const urlParams = new URLSearchParams(window.location.search);
const hash = urlParams.get('hash')
	, data = urlParams.get('data');

!function () {
	const a = document.querySelectorAll('li a:not(.signed)');

	const buttons = {};

	for (let i = 0, l = a.length; i < l; i++)
		buttons[a[i].dataset.type] = a[i];

	if (Object.keys(buttons).length === 0)
		return;

	const check = () => {
		fetch(window.endPoint, {
			method: 'POST',
			body: JSON.stringify({
				query,
				variables: { hash, data },
			}),
			headers: {
				'content-type': 'application/json',
			},
		}).then(res => res.json()).then(({ data: { status } }) => {
			let allComplete = true;
			for (let [key, val] of Object.entries(status)) {
				if (val === null) continue;
				if (val.status === 'complete') buttons[key].classList.add('signed');
				else allComplete = false;
			}

			if (allComplete) window.location.reload();
			else setTimeout(check, 5000);
		});
	};
	setTimeout(check, 5000);
}();

module.hot && module.hot.accept();
